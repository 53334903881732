<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="分类名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="rules.title"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '新增档案分类',

      form: this.$form.createForm(this),
      authArr: ['全员可见', '仅学员可见', '不对外可见'],
      rules: {
        title: [ 'title',
          {rules: [{ required: true, message: '请输入分类名称!' }]}
        ]
      },
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    const { item, authArr } = this
    if (item) {
      this.ModalTitle = '编辑档案分类'
      this.form.setFieldsValue({
        title: item.filter_name
      })
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.id = item.message_category
            params.message_category = item.message_category
          }
          let res = await this.$store.dispatch(item ? 'daMessagesUpdateCateAction' : 'daMessagesAddCateAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.submitModal()
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideModal()
    }
  }
}
</script>

<template>
  <div class="common zlgx">
    <category />
    <div class="c-main">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
              <a-button type="primary" icon="plus" @click="showEditModal($event)">添加档案</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="getList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学生姓名</span>
                </template>
                <a-input v-model.trim="searchParams.search.file_name" placeholder="学生姓名" style="width: 120px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>状态</span>
                </template>
                <a-select v-model="searchParams.search.auth_set" allowClear placeholder="状态" style="width: 120px">
                  <a-select-option v-for="(item, index) of C_ITEMS.fileAuths" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" @click="getList()">搜索</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="zl-list">
        <div class="title">
          <h3>{{ currentCategory.filter_name }}</h3>
        </div>
        <div class="archive-list">
          <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
          <div class="item" v-for="(item, index) of list" :key="index">
            <div class="user"><LImg :src="item.creater.teacher_avartar"/></div>
            <div class="text">
              <div class="text1">
                <div class="username">{{ item.creater.teacher_name }}</div>
                <div class="time">{{ item.message_created_at }}</div>
                <div class="tag" v-if="item.message_tag"><span>{{ item.message_tag }}</span></div>
              </div>
              <div class="text2">{{ item.message_content }}</div>
              <div class="text3"></div>
              <div class="photos" style="margin-bottom: 10px;">
                <LImg preview="0" v-for="(image, index) of item.message_images" :key="index" :src="image" />
              </div>
              <div class="photos" style="margin-bottom: 10px;">
                <audio v-for="(img, idx) of item.message_audio" :key="idx" :src="img.audio_path" controls />
              </div>
              <div class="photos" style="margin-bottom: 10px; max-width: 30%">
                <d-player v-for="(img, idx) of item.message_video" :key="idx" 
                  :options="{
                    video: {
                      url: img
                    }
                  }"/>
              </div>
              <div class="text4">
                <a class="num">点赞({{ item.like_count }})</a>
                <a class="num">评论({{ item.comment_count }})</a>
                <a class="num">回复</a>
              </div>
              <div class="comments" v-if="item.comments.length">
                <div class="comment" v-for="(comment, index) of item.comments" :key="index">
                  <span class="u">{{ comment.commnet_name }}: </span>{{ comment.commnet_content }}
                  <div class="comment" v-for="(c, index) of comment.commnet_children" :key="index">
                    <span class="u">{{ c.commnet_name }} 回复 {{ c.commnet_reply_to }}: </span>{{ c.comment_content }}
                  </div>
                </div>
              </div>
              <a-divider dashed />
            </div>
            <div class="btns">
              <!-- <div class="btn btn-parmary" @click="showEditModal($event, item)">编辑</div> -->
              <div v-if="canDelete" class="btn btn-delete" @click="deleteZl($event, item)">删除</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>

<script>
import C_ITEMS from '@/utils/items'
import category from './category/index'
import editModal from './editModal'
import authority from '@/common/mixins/authority'

export default {
  name: 'zlgx',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    category,
    editModal
  },

  data() {
    return {
      loading: false,
      C_ITEMS,
      currentCategory: { },
      list: [],

      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
        },
        "sort": ''
      },

      visible: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ authority ],
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
  },

  watch: {
    currentCategory() {
      this.getList()
    }
  },

  methods: {
    changeCategory(item) {
      this.currentCategory = item
    },
    async getList(e) {
      e?e.preventDefault():''
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      const { searchParams } = this
      searchParams.search = this.currentCategory
      let res = await this.$store.dispatch('daMessagesAction', { data: searchParams })
      console.log(res)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },

    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },

    deleteZl(e, item) {
      e.stopPropagation()
      let that = this
      this.$confirm({
        title: `确定要删除该档案吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('daMessagesDeleteAction', { data: {message_id: item.message_id} })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>

<template>
  <div class="common zlgx">
    <category />
    <div class="c-main" style="flex: 1">
      <div class="zl-list">
        <div class="title">
          <h3>详情</h3>
        </div>
        <div class="archive-list">
          <template v-for="(item, index) of list">
          <div class="msg-item" :key="index">
            <div class="panel">
              <div class="photo">
                <LImg v-if="item.is_sender === 1" :src="currentCategory.msg_sender.student_avartar" />
                <LImg v-else :src="currentCategory.msg_reciver.employee_avatar" />
              </div>
              <div class="message" style="max-width: calc(100% - 50px)">
                <div v-if="item.msg_content.msg_type==='txt'">{{ item.msg_content.msg_content }}</div>
                <div v-if="item.msg_content.msg_type==='image'">
                  <LImg preview="0" style="max-width: 30%" :src="item.msg_content.msg_content" />
                </div>
              </div>
            </div>
            <div class="datetime">{{ item.time }}</div>
          </div>
          </template>
        </div>
      </div>
      <div v-if='list.length == 0' style="width:100%;height:150px;position:relative;">
        <LEmpty />
      </div>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>
<style lang="scss" scoped>
.msg-item {
  margin: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &.right {
    align-items: flex-end;
  }
  .panel {
    display: flex;
  }
  .photo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .message {
    background: #f1f1f1;
    border-radius: 5px;
    padding: 10px;
  }
  .datetime {
    padding-left: 50px;
  }
}
</style>

<script>
import C_ITEMS from '@/utils/items'
import category from './category/index'
import editModal from './editModal'
import authority from '@/common/mixins/authority'

export default {
  name: 'zlgx',
  provide() {
    return {
      parent: this
    }
  },
  components: {
    category,
    editModal
  },

  data() {
    return {
      loading: false,
      C_ITEMS,
      currentCategory: { },
      list: [],

      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },

      searchParams: {
      },

      visible: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ authority ],
  created() {
    
  },

  watch: {
    currentCategory() {
      this.getList()
    }
  },

  methods: {
    changeCategory(item) {
      this.currentCategory = item
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      const { searchParams } = this
      searchParams.msg_id = this.currentCategory.msg_id
      if (!searchParams.msg_id) {
        return 
      }
      let res = await this.$store.dispatch('xtContactDetailAction', { data: searchParams })
      console.log(res)
      this.list = res.items
      this.pageParams = res._meta
    },

    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.getList()
    },

    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },

    deleteZl(e, item) {
      e.stopPropagation()
      let that = this
      this.$confirm({
        title: `确定要删除该动态吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('daMessagesDeleteAction', { data: {message_id: item.message_id} })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>

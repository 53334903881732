<template>
  <div class="c-side">
    <div class="buttons">
      <a-form layout='inline'>
        <a-form-item>
          <div style="font-size: 20px;">班级列表</div>
        </a-form-item>
      </a-form>
    </div>
    <div class="list">
      <perfect-scrollbar class="m-scrollbar">
      <div style="margin: 0 10px 10px 0;"><a-input v-if="canIndex" placeholder="搜索班级" v-model="searchParams.q" @keydown="search($event)"/></div>
      <div class="item" :class="{ 'active': !cateId }" @click="clickCategory({class_id: '', filter_name: '全部'})">
        <div class="name">全部班级</div>
      </div>
      <div class="item" :class="{ 'active': cateId === item.class_id }" 
        v-for="(item, index) of classes" :key="index" @click="clickCategory(item)">
        <div class="name">{{ item.filter_name }}</div>
        <div class="des"></div>
      </div>
      </perfect-scrollbar>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>
<style lang="scss" scoped>
.m-scrollbar {
  height: calc(100vh - 280px);
}
</style>
<script>
import editModal from './editModal'
import authority from '@/common/mixins/authority'

export default {
  name: 'Category',
  inject: [ 'parent' ],
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal
  },

  data() {
    return {
      loading: false,
      cateId: '',
      classes: [],
      searchParams: { q: '' },

      visible: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ authority ],
  async created() {
    await this.getList()
    this.parent.changeCategory({class_id: '', filter_name: '全部'})
  },

  methods: {
    async getList() {
      if(!this.canIndex){
          return false
      }
      let res = await this.$store.dispatch('searchClassesAction', {params: this.searchParams})
      this.classes = res.data
    },
    clickCategory(item) {
      this.cateId = item.class_id
      this.parent.changeCategory(item)
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },
    search(e) {
      if (e.keyCode === 13) {
        this.getList()
      }
    }
  }
}
</script>

<template>
  <div class="c-side" style="width: 300px;">
    <div class="buttons">
      <a-form layout='inline'>
        <a-form-item>
          <div style="font-size: 20px;">私信列表</div>
        </a-form-item>
      </a-form>
    </div>
    <div class="list" style="border-top: 1px solid #f1f1f1;">
      <div class="item" :class="{ 'active': cateId === item.msg_id }" 
        v-for="(item, index) of msgs" :key="index" @click="clickCategory(item)">
        <div class="photo">
          <LImg class="img1" :src="item.msg_sender.student_avartar" />
          <LImg class="img2" :src="item.msg_reciver.employee_avatar" />
        </div>
        <div class="text">
          <div class="name">{{ item.msg_sender.student_name }}
            <span class="time">{{ item.time }}</span>
          </div>
          <div class="des">{{ item.message }}
            <span class="new-message" v-if="item.new_message">新</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.item {
  display: flex;
  .photo {
    width: 56px;
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .img2 {
      margin-left: -10px;
    }
  }
  .text {
    flex: 1;
  }
}
.time {
  font-size: 12px;
  color: #999;
  display: inline-block;
}
.new-message {
  display: inline-block;
  background: #f00;
  color: #fff;
  font-size: 10px;
  padding: 0 5px;
  border-radius: 3px;
}
</style>

<script>
import authority from '@/common/mixins/authority'
export default {
  name: 'Category',
  inject: [ 'parent' ],
  provide() {
    return {
      parent: this
    }
  },

  data() {
    return {
      loading: false,
      cateId: '',
      msgs: [],
      searchParams: { q: '' },

      visible: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ authority ],
  async created() {
    await this.getList()
    this.parent.changeCategory({msg_id: '', class_name: '全部'})
  },

  methods: {
    async getList() {
      if(!this.canIndex){
          return false
      }
      let res = await this.$store.dispatch('xtContactAction', {})
      this.msgs = res.items
    },
    clickCategory(item) {
      this.cateId = item.msg_id
      this.parent.changeCategory(item)
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal() {
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    }
  }
}
</script>

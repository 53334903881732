<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>学习课代表</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="curTab" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">资料共享</span>
          <zlgx v-if="curTab === '1'"/>
        </a-tab-pane>

        <!-- <a-tab-pane key="2">
          <span slot="tab">学员档案</span>
          <xsda v-if="curTab === '2'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="3">
          <span slot="tab">班级动态</span>
          <bjdt v-if="curTab === '3'"/>
        </a-tab-pane> -->

        <a-tab-pane key="4">
          <span slot="tab">班级相册</span>
          <album v-if="curTab === '4'"/>
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">作业点评</span>
          <zydp v-if="curTab === '5'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="6">
          <span slot="tab">私信记录</span>
          <sxlb v-if="curTab === '6'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import zlgx from './zlgx'
import xsda from './xsda'
import bjdt from './bjdt'
import album from './album'
import zydp from './zydp'
import sxlb from './sxlb'

export default {
  name: 'Xuetang',
  components: {
    zlgx,
    xsda,
    bjdt,
    zydp,
    sxlb,
    album
  },
  data() {
    return {
      curTab: '1'
    }
  },

  created() {
  },

  methods: {
    changeTab(key) {
      this.curTab = key
    }
  }
}
</script>
